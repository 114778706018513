import { Box, Typography } from '@mui/material'
import React from 'react'

const ListOfInstructions = [
    'To add stores go to Add store section from bottom navigation bar',
    'Do not press back button while entering store details as it will wipe out previously saved data',
]

const Home = () => {



    return (
        <Box sx={{ m: 2 }}>
            <Typography component={'h2'} color={'gray'} pb={1}>Instructions to use Application:-</Typography>
            <ol style={{ margin: 0, paddingInlineStart: '1rem', textAlign: 'justify' }}>
                {
                    ListOfInstructions.map((instruction, idx) => {
                        return <li key={idx} style={{ color: 'gray', paddingBottom: '8px' }}>{instruction}</li>
                    })
                }
            </ol>
        </Box>
    )
}

export default Home
import { Alert, Button, FormControl, InputLabel, MenuItem, Select, Slide, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'
import { STORETYPE } from '../../constants/generalConstants';

const StoreInfo = (props) => {
    const { setStore } = props;

    const [storeInfo, setStoreInfo] = useState({
        name: '',
        mobile: '',
        storeType: ''
    });

    const [error, setError] = useState('');

    const onChangeHandler = (e) => {
        setStoreInfo(prevInfo => ({
            ...prevInfo,
            [e.target.name]: e.target.value
        }));

    }

    const submitHandler = (e) => {
        e.preventDefault();
        if (storeInfo.name.length === 0) {
            setError('Store name should not be empty');
            return;
        }
        if (storeInfo.mobile.length !== 10) {
            setError('Invalid mobile number');
            return;
        }
        if (storeInfo.storeType.length === 0) {
            setError('Store name should not be empty');
            return;
        }
        setError('');
        setStore(prev => ({
            ...prev,
            storeInfo: storeInfo,
            storeInfoLoaded: true
        }))
    }

    return (
        <Slide direction='right' in={true} timeout={400} mountOnEnter unmountOnExit>

            <form style={{ display: 'flex', flexDirection: 'column', rowGap: '1rem' }} onSubmit={submitHandler}>
                <Typography color={'gray'} mb={1}>Add store Info</Typography>
                <TextField required name="name" label="Store Name" variant="outlined" fullWidth value={storeInfo.name} onChange={onChangeHandler} />
                <TextField required name="mobile" label="Mobile Number" type='number' value={storeInfo.mobile} variant="outlined" fullWidth onChange={onChangeHandler} />
                <FormControl required fullWidth>
                    <InputLabel id="store-type">Store Type</InputLabel>
                    <Select
                        labelId="store-type"
                        label="StoreType"
                        name='storeType'
                        value={storeInfo.storeType}
                        onChange={onChangeHandler}
                    >
                        {
                            STORETYPE.map((type, idx) => {
                                return <MenuItem key={idx} value={type}>{type}</MenuItem>
                            })
                        }
                    </Select>
                </FormControl>
                {
                    error.length !== 0 &&
                    <Alert severity='error'>{error}</Alert>
                }
                <Button variant='contained' sx={{ color: 'white' }} color='secondary' type='submit'>Save Changes</Button>
            </form>
        </Slide>
    )
}

export default StoreInfo
import React, { useContext, useState } from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { SnackbarContext } from "./SnackbarContext";

export const SnackbarProvider = ({ children }) => {
  const [snackbarConfig, setSnackbarConfig] = useState(null);

  const showSnackbar = (message, severity = "info") => {
    setSnackbarConfig({ message, severity });
  };

  const closeSnackbar = () => {
    setSnackbarConfig(null);
  };

  return (
    <SnackbarContext.Provider value={{ showSnackbar, closeSnackbar }}>
      {children}
      {snackbarConfig && (
        <SnackbarComponent {...snackbarConfig} onClose={closeSnackbar} />
      )}
    </SnackbarContext.Provider>
  );
};

export const useSnackbar = () => {
  const context = useContext(SnackbarContext);
  if (!context) {
    throw new Error("useSnackbar must be used within a SnackbarProvider");
  }
  return context;
};

const SnackbarComponent = ({ message, severity, onClose }) => {
  return (
    <Snackbar
      open={true}
      autoHideDuration={6000}
      onClose={onClose}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
    >
      <MuiAlert
        elevation={6}
        variant='filled'
        onClose={onClose}
        severity={severity}
      >
        {message}
      </MuiAlert>
    </Snackbar>
  );
};

import { Box } from '@mui/material'
import React from 'react'
import AddStoreComponent from '../../components/add-store/AddStoreComponent'

const AddStore = () => {
    return (
        <Box sx={{ m: 2 }}>
            <AddStoreComponent />
        </Box>
    )
}

export default AddStore
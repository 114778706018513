import {baseUrl} from "../../constants";
import axios from "axios";
import axiosCommons from "./AxiosCommons";

const storeUserRestService = {
    addUser: (storeId, mobile) => {
        const url = new URL(`${baseUrl}user-management/stores/${storeId}/user`);
        const data = {
            mobile: mobile,
        }
        const axiosRes = axios.put(url, data);
        return axiosCommons.resolveResponse(axiosRes);
    },
    createUser: (name, mobile) => {
        const url = new URL(`${baseUrl}user-management/user`);
        const data = {
            name,
            mobile
        };
        const axiosRes = axios.post(url, data);
        return axiosCommons.resolveResponse(axiosRes);
    },
    removeUser: (storeId, mobile) => {
        const url = new URL(`${baseUrl}user-management/stores/${storeId}/users?mobile=` + mobile);
        const axiosRes = axios.delete(url);
        return axiosCommons.resolveResponse(axiosRes);
    }
};

export default storeUserRestService;
import { Capacitor } from '@capacitor/core';
import { Geolocation } from '@capacitor/geolocation';

export const requestGeolocationPermission = async () => {
    try {
        const permissionStatus = await Geolocation.checkPermissions();
        if (permissionStatus.location === 'granted') {
            return true;
        } else if (permissionStatus.location === 'prompt') {
            const requestStatus = await Geolocation.requestPermissions();
            return requestStatus.location === 'granted';
        } else {
            return false;
        }
    } catch (error) {
        console.error('Error in checking/requesting permission', error);
        return false;
    }
};

export const requestGeolocationPermissionWebapp = async () => {
    try {
        if ('geolocation' in navigator) {
            const permissionStatus = await navigator.permissions.query({ name: 'geolocation' });
            return permissionStatus.state === 'granted' || permissionStatus.state === 'prompt';
        } else {
            console.error('Geolocation API not supported in this browser.');
            return false;
        }
    } catch (error) {
        console.error('Error checking or requesting permission', error);
        return false;
    }
};

export const getCurrentLocation = async () => {
    try {
        let hasPermission;
        if (Capacitor.isNativePlatform()) {
            hasPermission = await requestGeolocationPermission();
        } else {
            hasPermission = await requestGeolocationPermissionWebapp();
        }
        if (hasPermission) {
            if (Capacitor.isNativePlatform()) {
                const coordinates = await Geolocation.getCurrentPosition();
                const { latitude, longitude } = coordinates.coords;
                return [latitude, longitude];
            } else {
                const { latitude, longitude } = await getCurrentCoordinatesForWebapp();
                return [latitude, longitude];
            }
        } else {
            console.error('Permission denied');
            return false;
        }
    } catch (error) {
        console.error('Error checking or requesting permission', error);
        return false;
    }
};

const getCurrentCoordinatesForWebapp = () => {
    return new Promise((resolve, reject) => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    resolve({ latitude, longitude });
                },
                (error) => {
                    reject(error);
                }
            );
        } else {
            reject(new Error('Geolocation is not supported in this browser.'));
        }
    });
};

import axios from "axios";
import {baseUrl} from "../../constants";
import axiosCommons from "./AxiosCommons";

const catalogue = {
  ImageUpload: (file) => {
    const url = new URL(`${baseUrl}files?fileType=PRODUCT_IMAGE`);
    const axiosResponse = axios.post(url, file);
    return axiosCommons.resolveResponse(axiosResponse);
  },
  SetCatalogueConfig: (
    payload
  ) => {
    const url = new URL(`${baseUrl}mdm/product-master`);
    const axiosResponse = axios.post(url, payload);
    return axiosCommons.resolveResponse(axiosResponse);
  },
  GetMetrics: () => {
    const url = new URL(`${baseUrl}metrics`);
    const axiosResponse = axios.get(url);
    return axiosCommons.resolveResponse(axiosResponse);
  }
};

export default catalogue;

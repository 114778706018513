import { Box, Button, Paper, Typography } from '@mui/material'
import React from 'react'

const Preview = (props) => {
    const { store, createStore, show } = props;

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: 1, color: 'gray' }}>
            <Typography color={'gray'} fontWeight={600}>Review store information</Typography>
            <Paper elevation={4} sx={{ display: 'flex', flexDirection: 'column', rowGap: 0.5, p: 1 }}>
                <Typography fontWeight={600} color={'gray'}>Store Info</Typography>
                <Typography color={'gray'} fontSize={'small'}>Store Name: {store.storeInfo.name}</Typography>
                <Typography color={'gray'} fontSize={'small'}>Store Mobile: {store.storeInfo.mobile}</Typography>
                <Typography color={'gray'} fontSize={'small'}>Store Type: {store.storeInfo.storeType}</Typography>
            </Paper>
            <Paper elevation={4} sx={{ display: 'flex', flexDirection: 'column', rowGap: 0.5, p: 1 }}>
                <Typography fontWeight={600} color={'gray'}>Store Address</Typography>
                <Typography color={'gray'} fontSize={'small'} display={'flex'}>Address Line: {store.storeAddress.addressLine}</Typography>
                <Typography color={'gray'} fontSize={'small'}>Pincode: {store.storeAddress.zip}</Typography>
                <Typography color={'gray'} fontSize={'small'}>Latitude: {store.storeAddress.lat}</Typography>
                <Typography color={'gray'} fontSize={'small'}>Longitude: {store.storeAddress.lng}</Typography>
            </Paper>
            <Paper elevation={4} sx={{ display: 'flex', flexDirection: 'column', rowGap: 0.5, p: 1 }}>
                <Typography fontWeight={600} color={'gray'}>Payment mode accepted by store</Typography>
                <Typography color={'gray'} fontSize={'small'} display={'flex'}>Payment Modes: Cash on delivery</Typography>
            </Paper>
            {
                show === 0 &&
                <Button variant='contained' sx={{ color: 'white' }} color='secondary' type='submit' onClick={() => { createStore(store) }}>Onboard store to Lifease</Button>
            }
        </Box>
    )
}

export default Preview
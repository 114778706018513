import { Alert, Avatar, Box, Button, Container, CssBaseline, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// import ProgressBackdrop from "../../common/ProgressBackdropComponent";
import { getFirebaseToken, putToken, putLoggedInStore } from "../../indexeddb/AppContext";
import register from "../../capacitor/PushNotificationsContainer";
import loginRestService from "../../services/rest/LoginRestService";
import TimerComponent from "../../components/login/TimerComponent";
import { handleLoginSuccess } from "../../components/login/RefreshLogin";

const initialLoginState = {
    phone: '',
    isPhoneValidated: false,
    phoneValidationMessage: '',
    otp: '',
    isOtpValidated: false,
    optValidationMessage: null,
    loading: false
};


function StoreLogin() {
    const [loginForm, setLoginForm] = useState(initialLoginState);
    const navigate = useNavigate();
    useEffect(() => register(), []);

    const ResetPhone = () => {
        const formReset = () => {

            setLoginForm(prevForm => ({
                ...prevForm,
                isPhoneValidated: false,
                phone: '',
                otp: '',
                optValidationMessage: '',
                isOtpValidated: false,
                phoneValidationMessage: '',
                loading: false
            }))
        }

        return (
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography color='GrayText' onClick={formReset} sx={{ cursor: 'pointer' }}>clear number</Typography>
                <TimerComponent timerFunction={() => { handlePhoneSubmit(loginForm.phone) }} />
            </Box>
        )
    }

    const helperText = "Lifease sales for onboarding stores";

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoginForm(prevForm => ({
            ...prevForm,
            loading: true
        }));
        if (loginForm.otp.length === 4) {
            try {
                const firebaseToken = await getFirebaseToken();
                const res = await loginRestService.verifyOtp(loginForm, firebaseToken);
                await handleLoginSuccess({ data: res });
                setLoginForm(prevForm => ({
                    ...prevForm,
                    isOtpValidated: true,
                    optValidationMessage: null
                }));
                navigateHome();

            } catch (err) {
                setLoginForm(prevForm => ({
                    ...prevForm,
                    isOtpValidated: false,
                    optValidationMessage: err
                }));
            } finally {
                setLoginForm(prevForm => ({
                    ...prevForm,
                    loading: false
                }));
            }
        }
    }

    const handleChange = (e) => {
        setLoginForm(prevLogin => ({
            ...prevLogin,
            [e.target.name]: e.target.value
        }));
    }

    const sendOtpClick = () => {
        handlePhoneSubmit(loginForm.phone);
        setLoginForm(prevForm => ({
            ...prevForm,
            isPhoneValidated: true
        }));
    }

    return (
        <Container direction="column" maxWidth="md" sx={{ padding: 1 }}>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Avatar sx={{ m: 1, width: 56, height: 56 }} src='/favicon.ico' />
                    <Typography component="h1" variant="h5" color='primary'>
                        Sign in
                    </Typography>
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="phoneNumber"
                            label="Phone Number"
                            name="phone"
                            type="number"
                            autoComplete="phone"
                            autoFocus
                            helperText={helperText}
                            value={loginForm.phone}
                            onChange={handleChange}
                            disabled={loginForm.isPhoneValidated}
                        />
                        {
                            loginForm.isPhoneValidated &&
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="otp"
                                label="OTP"
                                type="number"
                                id="otp"
                                autoComplete="otp"
                                onChange={handleChange}
                            />
                        }
                        {
                            loginForm.optValidationMessage &&
                            <Alert severity="error">Invalid OTP, please try again</Alert>
                        }
                        {
                            !loginForm.isPhoneValidated &&
                            <Button
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                                disabled={loginForm.phone.length !== 10}
                                onClick={sendOtpClick}
                            >
                                Send OTP
                            </Button>
                        }
                        {
                            loginForm.isPhoneValidated &&
                            <>
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    sx={{ mt: 3, mb: 2 }}
                                    disabled={loginForm.otp.length !== 4 || loginForm.loading}
                                >
                                    Verify OTP
                                </Button>
                                <ResetPhone />
                            </>
                        }
                    </Box>
                </Box>
            </Container>
            {/* <ProgressBackdrop progress={false}></ProgressBackdrop> */}
        </Container>
    );


    function navigateHome() {
        navigate("/", { replace: true });
    }

    async function handlePhoneSubmit(phoneNumber) {
        try {
            const res = await loginRestService.setRegisteredNumber(phoneNumber);
        } catch (err) {

        } finally {

        }
    }
}

export default StoreLogin;
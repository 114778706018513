import React, { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { refreshAndCheckLogin } from "../login/RefreshLogin";

const AuthGuard = () => {
  const [loggedIn, setLoggedIn] = useState(null);
  const navigate = useNavigate();

  const getAuth = async () => {
    try {
      const res = await refreshAndCheckLogin();
      if (res) setLoggedIn(true);
      else {
        navigate("/login");
        setLoggedIn(false);
      }
    } catch (err) {
      navigate("/login");
      setLoggedIn(false);
    }
  };
  useEffect(() => {
    getAuth();
  }, []);

  return <>{loggedIn && <Outlet />}</>;
};

export default AuthGuard;

import {
  Alert,
  Box,
  Button,
  IconButton,
  Slide,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { getCurrentLocation } from "../../services/location/geoLocation";
import MyLocationIcon from "@mui/icons-material/MyLocation";

const StoreAddress = (props) => {
  const { setStore } = props;

  const [storeAddress, setStoreAddress] = useState({
    addressLine: [],
    zip: [],
    lat: 0.0,
    lng: 0.0,
  });

  const [error, setError] = useState("");

  const onChangeHandler = (e) => {
    setStoreAddress((prev) => ({
      ...prev,
      [e.target.name]:
        e.target.name === "lat" || e.target.name === "lng"
          ? parseFloat(e.target.value)
          : [e.target.value],
    }));
  };
                                                                                

  const submitHandler = (e) => {
    e.preventDefault();
    setStore((prev) => ({
      ...prev,
      storeAddress: storeAddress,
      storeAddressLoaded: true,
    }));
  };

  const getLocation = () => {
    getCurrentLocation().then((res) => {
      const [lat, lng] = res;
      setStoreAddress((prev) => ({
        ...prev,
        lat: parseFloat(lat),
        lng: parseFloat(lng),
      }));
    });
  };

  return (
    <Slide direction="right" in={true} timeout={400}>
      <form
        style={{ display: "flex", flexDirection: "column", rowGap: "1rem" }}
        onSubmit={submitHandler}
      >
        <Typography color={"gray"} mb={1}>
          Add store Address
        </Typography>
        <TextField
          required
          name="addressLine"
          multiline
          label="Address Line"
          variant="outlined"
          fullWidth
          value={storeAddress.addressLine.join("\n")}
          onChange={onChangeHandler}
        />
        <TextField
          required
          name="zip"
          label="Pincode"
          type="number"
          value={storeAddress.zip.join("")}
          variant="outlined"
          fullWidth
          onChange={onChangeHandler}
        />
        <Box sx={{ display: "flex", columnGap: 1 }}>
          <TextField
            required
            name="lat"
            label="Latitude"
            type="number"
            value={storeAddress.lat}
            variant="outlined"
            fullWidth
            onChange={onChangeHandler}
          />
          <TextField
            required
            name="lng"
            label="Longitude"
            type="number"
            value={storeAddress.lng}
            variant="outlined"
            fullWidth
            onChange={onChangeHandler}
          />
          <IconButton
            onClick={getLocation}
            aria-label="delete"
            size="medium"
            color={"secondary"}
          >
            <MyLocationIcon fontSize="inherit" />
          </IconButton>
        </Box>
        {error.length !== 0 && <Alert severity="error">{error}</Alert>}
        <Button
          variant="contained"
          sx={{ color: "white" }}
          color="secondary"
          type="submit"
        >
          Save Changes
        </Button>
      </form>
    </Slide>
  );
};

export default StoreAddress;

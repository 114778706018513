import * as React from 'react';
import Box from '@mui/material/Box';
import StoreInfo from './StoreInfo';
import { Alert, Button, LinearProgress, Typography } from '@mui/material';
import StoreAddress from './StoreAddress';
import PaymentModeComponent from './PaymentModeComponent';
import Preview from './Preview';
import { useNavigate } from 'react-router-dom';
import storeOnboard from '../../services/rest/StoreOnBoardService';

export default function AddStoreComponent() {

    const [store, setStore] = React.useState({
        storeInfo: null,
        storeInfoLoaded: false,
        storeAddress: null,
        storeAddressLoaded: false,
        storePayment: null,
        storePaymentsLoaded: false,
        paymentMapping: null,
        storeUsers: null,
        storeUsersLoaded: false
    });

    const [storeId, setStoreId] = React.useState(null);

    const [errorMessage, setErrorMessage] = React.useState('');

    const [completion, setCompletion] = React.useState({
        storeInfo: false,
        storeAddress: false,
        storePayment: false,
        paymentMapping: false
    });

    const [loading, setLoading] = React.useState(false);

    const [error, setError] = React.useState({
        storeInfo: false,
        storeAddress: false,
        storePayment: false,
        paymentMapping: false
    });

    const [storeLoading, setStoreLoading] = React.useState({
        storeCreation: false,
        checks: 0
    })

    const TOTAL_COUNT = 3;
    const completed = () => {
        let count = 0;
        count += (store.storeAddressLoaded) ? 1 : 0;
        count += (store.storeInfoLoaded) ? 1 : 0;
        count += (store.storePaymentsLoaded) ? 1 : 0;
        return count;
    }

    const navigate = useNavigate();

    const clickHandler = () => {
        
        navigate(`/add-user?storeId=${storeId}`);
        
    }

    const createStore = async (storeCompleted) => {
        setLoading(true);
        setStoreLoading((prev) => ({
            ...prev,
            storeCreation: true
        }));
        const { storeInfo, storeAddress } = storeCompleted;
        let storeId;
        try {
            const storeInfoResponse = await storeOnboard.setStores(storeInfo.mobile, storeInfo.name, storeInfo.storeType);
            storeId = storeInfoResponse.partyId;
            setStoreId(storeId);
            setCompletion(prev => ({
                ...prev,
                storeInfo: true
            }));
            setStoreLoading((prev) => ({
                ...prev,
                checks: 25
            }));
        } catch (err) {
            setErrorMessage(err);
            setError(prev => ({
                ...prev,
                storeInfo: true
            }));
            setLoading(false);
            setStoreLoading((prev) => ({
                ...prev,
                storeCreation: false
            }))
        }

        if (storeId) {
            try {
                const storeAddressRes = await storeOnboard
                    .setStoreAddress(
                        storeId,
                        storeAddress.zip[0],
                        storeAddress.addressLine[0],
                        `POINT(${storeAddress.lat} ${storeAddress.lng})`,
                        storeAddress.lat,
                        storeAddress.lng
                    );
                setCompletion((prev) => ({
                    ...prev,
                    storeAddress: true
                }))
                setStoreLoading((prev) => ({
                    ...prev,
                    checks: 50
                }));
            } catch (err) {
                setErrorMessage(err);
                setError(prev => ({
                    ...prev,
                    storeAddress: true
                }));
            }
            try {
                const storePaymentRes = await storeOnboard.setStoreConfig(storeId);
                setCompletion(prev => ({
                    ...prev,
                    storePayment: true
                }));
                setStoreLoading((prev) => ({
                    ...prev,
                    checks: 75
                }));

            } catch (err) {
                setErrorMessage(err);
                setError(prev => ({
                    ...prev,
                    storePayment: true
                }));
            } 
            try {
                const paymentMappingRes = await storeOnboard.setPaymentMapping(storeId);
                setCompletion(prev => ({
                    ...prev,
                    paymentMapping: true
                }));
                setStoreLoading((prev) => ({
                    ...prev,
                    checks: 100
                }));

            } catch (err) {
                setErrorMessage(err);
                setError(prev => ({
                    ...prev,
                    paymentMapping: true
                }));

            } finally {
                setLoading(false);
                setStoreLoading((prev) => ({
                    ...prev,
                    storeCreation: false
                }));
            }
        }
    }

    return (
        <Box sx={{ mb: 14 }}>
            <Typography component={'h2'} color={'gray'} fontWeight={600}>
                Store Onboarding
            </Typography>
            {!storeLoading.storeCreation &&
                <>
                    <Typography fontSize={'small'} color={'gray'} mb={2}>{completed()} out of {TOTAL_COUNT} steps completed</Typography>
                    {
                        !store.storeInfoLoaded &&
                        <StoreInfo setStore={setStore} />
                    }
                    {
                        store.storeInfoLoaded && !store.storeAddressLoaded &&
                        <StoreAddress setStore={setStore} />
                    }
                    {
                        store.storeInfoLoaded && store.storeAddressLoaded && !store.storePaymentsLoaded &&
                        <PaymentModeComponent setStore={setStore} />
                    }
                    {
                        store.storeInfoLoaded && store.storeAddressLoaded && store.storePaymentsLoaded &&
                        <Preview store={store} createStore={createStore} show={storeLoading.checks} />
                    }

                </>
            }
            {
                loading &&
                <>
                    <Typography color={'gray'} mt={2} mb={2}>Store creation on its way, please wait</Typography>
                    <LinearProgress color='inherit' variant="determinate" value={storeLoading.checks} />
                </>
            }
            {
                (error.storeInfo || error.storeAddress || error.storePayment || error.paymentMapping) &&
                <Alert sx={{ mt: 1 }} severity='error'>{errorMessage}</Alert>
            }
            {
                completion.storeInfo && completion.storeAddress && completion.storePayment && completion.paymentMapping &&
                <>
                    <Alert sx={{ mt: 1 }} severity='success'>
                        Store onboarded successfully!!!
                    </Alert>
                    <Button onClick={clickHandler} variant='contained' sx={{ color: 'white', mt: 1, mb: 14 }} color='secondary' type='submit'>Add users to store</Button>
                </>
            }
        </Box >
    );
}
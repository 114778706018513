import React, { useState } from "react";
import storeUpdate from "../../services/rest/StoreUpdateRestServices";

import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { STORETYPE, status } from "../../constants/generalConstants";
import { useSnackbar } from "../../utility/Snackbar";

const StoreDetails = ({ setStoreInfo, storeInfo, storeId }) => {
  const [isDisabled, setIsDisabled] = useState(false);
  const { showSnackbar } = useSnackbar();

  const handleonChange = (e) => {
    setStoreInfo((prevStoreInfo) => ({
      ...prevStoreInfo,
      [e.target.name]: e.target.value,
    }));
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    try {
      setIsDisabled(true);
      const { mobile, storeName, storeType, description, status } = storeInfo;
      await storeUpdate.updateStore(
        storeId,
        mobile,
        storeName,
        storeType,
        description,
        setStoreInfo.prevStatus === status ? null : status
      );

      setStoreInfo((prevStoreInfo) => ({
        ...prevStoreInfo,
        prevStatus: status,
      }));

      showSnackbar("Store updated successfully", "success");
    } catch (err) {
      showSnackbar(err, "error");
    } finally {
      setIsDisabled(false);
    }
  };
  return (
    <form
      style={{ display: "flex", flexDirection: "column", rowGap: "1rem" }}
      onSubmit={submitHandler}
    >
      <Typography component='h2' sx={{ mt: 2 }} color={"gray"} fontWeight={600}>
        Store Details
      </Typography>
      <TextField
        required
        name='storeName'
        label='Store Name'
        variant='outlined'
        sx={{ mt: 2 }}
        fullWidth
        value={storeInfo.storeName}
        onChange={handleonChange}
      />
      <TextField
        required
        name='mobile'
        label='Mobile Number'
        type='number'
        value={storeInfo.mobile}
        variant='outlined'
        sx={{ mt: 2 }}
        disabled
        fullWidth
      />
      <FormControl fullWidth sx={{ mt: 2 }}>
        <InputLabel id='store-type' required>
          Store Type
        </InputLabel>
        <Select
          required
          labelId='store-type'
          label='StoreType'
          name='storeType'
          value={storeInfo.storeType}
          onChange={handleonChange}
        >
          {STORETYPE.map((type, idx) => {
            return (
              <MenuItem key={idx} value={type}>
                {type}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>

      <FormControl fullWidth sx={{ mt: 2 }}>
        <InputLabel id='status' required>
          Status
        </InputLabel>
        <Select
          labelId='status'
          label='Status'
          name='status'
          value={storeInfo.status}
          onChange={handleonChange}
        >
          {status.map((status) => (
            <MenuItem key={status} value={status}>
              {status}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Button
        variant='contained'
        disabled={isDisabled}
        sx={{ color: "white" }}
        color='secondary'
        type='submit'
      >
        Save Changes
      </Button>
    </form>
  );
};

export default StoreDetails;

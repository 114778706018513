import Dexie from 'dexie';
export class MySubClassedDexie extends Dexie {
    //cartItems = db.stores("cartItems");
    constructor() {
        super('lifease-sales');
        this.version(1).stores({
            appConext: 'key'
        });
    }
}
export const db = new MySubClassedDexie();
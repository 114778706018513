import axios from "axios";
import axiosCommons from "./AxiosCommons";
import  {baseUrl, APP_NAME } from "../../constants";

const loginRestService = {
    setRegisteredNumber: async (phoneNumber) => {
        axios.defaults.withCredentials = true;
        const url = new URL(`${baseUrl}auth/login/otp/${phoneNumber}`);
        const axiosResponse = axios.put(url);
        return axiosCommons.resolveResponse(axiosResponse);
    },
    verifyOtp: async (formDetails, firebaseToken) => {
        axios.defaults.withCredentials = true;
        const data = {
            mobile: formDetails.phone,
            otp: formDetails.otp,
            app: APP_NAME,
            firebaseToken: firebaseToken
        }
        const url = new URL(`${baseUrl}auth/login/customer/otp`);
        const axiosResponse = axios.post(url, data);
        return axiosCommons.resolveResponse(axiosResponse);
    },
    customerApi: async () => {
        axios.defaults.withCredentials = true;
        const url = new URL(`${baseUrl}customer`);
        const axiosResponse = axios.put(url);
        return axiosCommons.resolveResponse(axiosResponse);
    }
};

export default loginRestService;
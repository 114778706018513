import { Box, Button, Checkbox, FormControlLabel, FormGroup, Typography } from '@mui/material';
import React, { useState } from 'react'

const PaymentModeDetails = {
    "COD": {
        mode: "COD",
        displayText: "Payment on delivery",
        gatewayProviderConfigId: null,
        method: "OFFLINE"
    }
}

const PaymentModeComponent = (props) => {
    const { setStore } = props;

    const [paymentMode, setPaymentMode] = useState({
        paymentMode: PaymentModeDetails['COD']
    });

    const submitHandler = (e) => {

        e.preventDefault();
        setStore(prev => ({
            ...prev,
            storePayments: paymentMode,
            storePaymentsLoaded: true
        }));
    }

    return (
        <FormGroup onSubmit={submitHandler}>
            <Typography mb={1} color={'gray'}>Add payment mode accepted by Store</Typography>
            <FormControlLabel control={<Checkbox checked={true} />} required sx={{ color: 'gray', mb: 1 }} label="Cash on delivery" />
            <Typography mb={1} color={'gray'}>Online payments coming soon</Typography>
            <Button variant='contained' sx={{ color: 'white' }} color='secondary' type='submit' onClick={submitHandler}>Save Changes</Button>
            {/* <FormControlLabel disabled control={<Checkbox />} label="Online Payments (coming soon)" /> */}
        </FormGroup>
    )
}

export default PaymentModeComponent
import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import ProductDetails from "../../components/catalogue/ProductDetails";
import ImageCropper from "../../components/catalogue/ImageCropper";
import { useTheme } from '@mui/material/styles';

const AddCatalogue = () => {
  const [cropData, setCropData] = useState(null);
  const theme = useTheme();
  return (
    <Box sx={{ mb: 14, mx: 2 }}>
      <Typography component='h2' color={theme.palette.primary.main} fontWeight={600} sx={{ mb: 2 }}>
        Product Details
      </Typography>
      <Typography component='h5' color={theme.palette.primary.main} fontWeight={400} sx={{ mb: 2 }}>
        Catalogue Image
      </Typography>
      <ImageCropper setCropData={setCropData} cropData={cropData} />
      <ProductDetails cropData={cropData} />
    </Box>
  );
};

export default AddCatalogue;

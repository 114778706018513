import { BottomNavigation, BottomNavigationAction, Box } from '@mui/material'
import React, { useEffect, useState } from 'react';
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import HomeIcon from '@mui/icons-material/Home';
import styled from '@emotion/styled';
import { useLocation, useNavigate } from 'react-router-dom';

const TabBarButton = styled(BottomNavigationAction)({
    color: 'white',
    '&.Mui-selected': {
        backgroundColor: 'white',
        top: '-6px',
        color: 'black'
    },
});

const Navbar = () => {

    const location = useLocation();

    const [value, setValue] = useState(0);
    const navigate = useNavigate();

    useEffect(() => {
        if (location.pathname.includes('add-store')) {
            setValue(1);
        } else if (location.pathname.includes('add-user')) {
            setValue(-1);
        } else {
            setValue(0)
        }
    }, [location.pathname]);

    return (
        <Box
            sx={{
                zIndex: 5,
                position: 'fixed',
                display: 'block',
                bottom: '0px',
                mx: 'auto',
                width: '100vw',
            }}
        >
            <BottomNavigation
                showLabels

                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    backgroundColor: 'black',
                    p: 0.5,
                    pb: 1,
                }}
                value={value}
                onChange={(event, newValue) => {
                    if (value !== newValue) {
                        switch (newValue) {
                            case 0: {
                                navigate('/');
                                break;
                            }
                            case 1: {
                                navigate('/add-store');
                                break;
                            }
                        }
                    }
                    setValue(newValue);
                }}
            >
                <TabBarButton disableRipple label="Home" icon={<HomeIcon />} />
                <TabBarButton disableRipple label="Add Stores" icon={<AddBusinessIcon />} />
            </BottomNavigation>
        </Box>

    )
}

export default Navbar;
import StoreIcon from '@mui/icons-material/Store';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import PersonAddAltRoundedIcon from '@mui/icons-material/PersonAddAltRounded';
import PersonRemoveRoundedIcon from '@mui/icons-material/PersonRemoveRounded';

export const STORETYPE = [
  'GROCERY',
  'MEAT_AND_SEAFOOD',
  'NURSERY',
  'STATIONARY',
  'BAKERY_AND_SNACK',
  'LAUNDRY',
  'PHARMACY',
  'RESTAURANT',
  'APPAREL',
];

export const status = ['ACTIVE', 'ONBOARDING', 'DISABLED', 'BLOCKED'];

export const menuItems = [
  { text: 'Update Store', path: '/update-store', icon: <StoreIcon /> },
  {
    text: 'Add store user',
    path: '/add-user',
    icon: <PersonAddAltRoundedIcon />,
  },
  {
    text: 'Remove store user',
    path: '/remove-user',
    icon: <PersonRemoveRoundedIcon />,
  },
  {
    text: 'Add Catalogue',
    path: '/add-catalogue',
    icon: <AddShoppingCartIcon />,
  },
];

import axios from "axios";
import {baseUrl} from '../../constants';
import axiosCommons from './AxiosCommons';
const storeUpdate = {
    getStoreById : (storeId) => {
        const url = new URL(`${baseUrl}stores/${storeId}`);
        const axiosResponse = axios.get(url);
        return axiosCommons.resolveResponse(axiosResponse);
    },
    updateStore: (storeId, mobile, storeName, storeType, description , status ) => {
        const url = new URL(`${baseUrl}stores/${storeId}`);
        const axiosResponse = axios.put(url, {storeType, storeName,description,mobile, status });
        return axiosCommons.resolveResponse(axiosResponse);
    },
};

export default storeUpdate;
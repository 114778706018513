import { Box, Button, IconButton, TextField, Typography, Paper, Alert } from '@mui/material';
import React, { useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import storeUserRestService from '../../services/rest/StoreUserRestService';

const AddUser = (props) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const storeId = searchParams.get('storeId');
    const [users, setUsers] = useState([]);
    const [user, setUser] = useState({ name: '', mobile: '', storeId: storeId });
    const [loading, setLoading] = useState(false);
    const [userStatus, setUserStatus] = useState(false);
    const [error, setError] = useState('');

    const onChangeHandler = (e) => {
        setUser(prev => ({
            ...prev,
            [e.target.name]: e.target.value
        }))
    }

    const submitHandler = async () => {
        setLoading(true);
        setError('');
        setUserStatus(false);
        try {
            if (user.mobile.length === 10) {
                const addUserRes = await addUserFn(user.storeId, user.mobile);
                if (!addUserRes) {
                    await createUserFn(user.name, user.mobile);
                    await addUserFn(user.storeId, user.mobile);
                }
                
                setUsers((prevUsers) => [...prevUsers, user]);
                setUser({ name: '', mobile: '', storeId: storeId });
                setLoading(false);
                setUserStatus(true);
            }
            else {
                setLoading(false);
                setError("Pls enter valid mobile number");
            }
        } catch (err) {
            setLoading(false);
            setUserStatus(true);
            setError(err.message);
        }
    }

    const addUserFn = async (storeId, mobile) => {
        try {
            const res = await storeUserRestService.addUser(storeId, mobile);
            return true;
        } catch (err) {
            return false;
        }
    }

    const createUserFn = async (name, mobile) => {
        try {
            const res = await storeUserRestService.createUser(name, mobile);
            return true;
        } catch (err) {
            throw new Error(err);
        }
    }

    return (
        <Box sx={{ mb: 14 }}>
            <Typography mb={1} color={'gray'}>Add users to store</Typography>
            {
                users.map((user, idx) => {
                    return <Paper sx={{ p: 1, m: 1 }} elevation={2} key={idx}>
                        <TextField sx={{ mb: 1 }} disabled label="User added" type='text' value={user.name} variant="outlined" fullWidth />
                        <TextField sx={{ mb: 1 }} disabled label="Mobile Number added" type='number' value={user.mobile} variant="outlined" fullWidth />
                    </Paper>
                })
            }
            {
                userStatus && error.length === 0 &&
                <Alert severity='success' sx={{ my: 1 }}>User added to store successfully</Alert>
            }
            <TextField sx={{ mb: 1, mt: 1 }} required name="storeId" onChange={onChangeHandler} value={user.storeId} label="StoreId" type='number' variant="outlined" fullWidth />
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                <TextField sx={{ mb: 1, mt: 1 }} required name="name" onChange={onChangeHandler} value={user.name} label="Name" type='text' variant="outlined" fullWidth />
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                <TextField required name="mobile" onChange={onChangeHandler} value={user.mobile} label="Mobile Number" type='number' variant="outlined" fullWidth />
            </Box>
            {
                error.length > 0 &&
                <Alert severity='error' sx={{ my: 1 }}>{error}</Alert>
            }
            <Button disabled={loading} variant='contained' sx={{ color: 'white' }} color='secondary' type='submit' fullWidth onClick={submitHandler}>Add User</Button>
        </Box>
    );
}

export default AddUser;
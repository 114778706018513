import "./App.css";
import StoreLogin from "./pages/login/StoreLoginComponent";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material";
import Layout from "./components/header/Layout";
import Home from "./pages/home/Home";
import AddStore from "./pages/add-store/AddStore";
import AuthGuard from "./components/RouteGuard/AuthGuard";
import Header from "./components/header/Header";
import AddUserPage from "./pages/add-user/AddUserPage";
import UpdateStore from "./pages/update-store/UpdateStore";
import AddCatalogue from "./pages/Catalogue/AddCatalogue";
import { SnackbarProvider } from "./utility/Snackbar";
import RemoveUserPage from "./pages/remove-user/RemoveUserPage";

const salesTheme = createTheme({
  palette: {
    background: {
      default: "#FFFFFF",
    },
    primary: {
      main: "#808080",
    },
    secondary: {
      main: "#000000FF",
    },
    dark: {
      main: "#212529",
      contrastText: "#FFFFFF",
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={salesTheme}>
      <SnackbarProvider>
        <BrowserRouter>
          <Routes>
            <Route path='/' element={<AuthGuard />}>
              <Route path='' element={<Layout />}>
                <Route path='' element={<Home />} />
                <Route path='add-store' element={<AddStore />} />
                <Route path='add-user' element={<AddUserPage />} />
                <Route path='remove-user' element={<RemoveUserPage />} />
                <Route path='update-store' element={<UpdateStore />} />
                <Route path='add-catalogue' element={<AddCatalogue />} />
              </Route>
            </Route>
            <Route
              path='/login'
              element={
                <>
                  <Header />
                  <StoreLogin />
                </>
              }
            ></Route>
          </Routes>
        </BrowserRouter>
      </SnackbarProvider>
    </ThemeProvider>
  );
}

export default App;

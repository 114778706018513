import React, { useState } from "react";
import { Box } from "@mui/material";
import storeUpdate from "../../services/rest/StoreUpdateRestServices";
import StoreDetails from "../../components/update-store/StoreDetails";
import { useSnackbar } from "../../utility/Snackbar";
import GetStoreDetails from "../../components/update-store/GetStoreDetails";

const UpdateStore = () => {
  const [storeId, setStoreId] = useState("");
  const [storeInfo, setStoreInfo] = useState(null);
  const [isDisabled, setIsDisabled] = useState(false);
  const { showSnackbar } = useSnackbar();

  const handleStoreDetails = async () => {
    try {
      setIsDisabled(true);
      const storeByIdInfoResponse = await storeUpdate.getStoreById(storeId);
      setStoreInfo({
        ...storeByIdInfoResponse,
        prevStatus: storeByIdInfoResponse.status,
      });
      showSnackbar("Store details fetched successfully", "success");
    } catch (err) {
      showSnackbar(err.message, "error");
      setStoreId("");
      setStoreInfo(null);
    } finally {
      setIsDisabled(false);
    }
  };

  return (
    <Box
      sx={{
        m: 2,
        display: "flex",
        flexDirection: "column",
        mb: 14
      }}
    >
      <GetStoreDetails
        storeId={storeId}
        setStoreId={setStoreId}
        isDisabled={isDisabled}
        handleStoreDetails={handleStoreDetails}
      />
      {storeInfo && (
        <StoreDetails
          setStoreInfo={setStoreInfo}
          storeInfo={storeInfo}
          storeId={storeId}
        />
      )}
    </Box>
  );
};

export default UpdateStore;

import React from 'react'
import { Box } from '@mui/material';
import RemoveUser from '../../components/remove-user/RemoveUser';

const RemoveUserPage = () => {
    return (
        <Box sx={{ ml: 2, mr: 2 }}>
            <RemoveUser />
        </Box>
    )
}

export default RemoveUserPage
import * as React from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { IconButton, Typography } from "@mui/material";
import WidgetsRoundedIcon from "@mui/icons-material/WidgetsRounded";
import { menuItems } from "../../constants/generalConstants";
import { useTheme } from '@mui/material/styles';

export default function Header() {
  const theme = useTheme();
  const [state, setState] = React.useState({
    top: false,
  });

  const navigate = useNavigate();

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };
 
  const list = (anchor) => (
    <Box
      zIndex={5}
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        {menuItems.map((item, index) => (
          <ListItem
            key={item.text}
            disablePadding
            onClick={() => {
              navigate(item.path);
            }}
          >
            <ListItemButton>
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <Box sx={{ display: "block", width: "100vw", top: "0", zIndex: 5 }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          height: "100%",
          py: 1,
          alignItems: "center",
          backgroundColor: "white",
        }}
      >
        <Typography sx={{ color: theme.palette.primary.main, ml: 2 }}>Lifease Sales</Typography>
        <IconButton aria-label="delete" sx={{ mr: 2 }}>
          <WidgetsRoundedIcon onClick={toggleDrawer("top", true)} />
        </IconButton>
      </Box>
      <Drawer
        anchor={"top"}
        open={state["top"]}
        onClose={toggleDrawer("top", false)}
      >
        {list("top")}
      </Drawer>
    </Box>
  );
}

import axios from "axios";
import { baseUrl, paymentsBaseUrl } from "../../constants";
import axiosCommons from "./AxiosCommons";

const storeOnboard = {
  setStores: (mobile, storeName, storeType, description = null) => {
    axios.defaults.withCredentials = true;
    const url = new URL(`${baseUrl}stores`);
    const axiosResponse = axios.post(url, {
      mobile,
      storeName,
      storeType,
      description,
    });
    return axiosCommons.resolveResponse(axiosResponse);
  },
  setStoreAddress: (storeId, zip, addressLine, point, lat, lng) => {
    const url = new URL(`${baseUrl}stores/${storeId}/address`);
    const axiosResponse = axios.post(url, {
      zip,
      addressLine,
      point,
      lat,
      lng,
    });
    return axiosCommons.resolveResponse(axiosResponse);
  },
  setStoreConfig: (storeId) => {
    const url = new URL(`${baseUrl}stores/${storeId}/config`);
    const body = {
      paymentModes: ["COD"],
      providerInfoIds:[1],
      paymentModeDetails: [
        {
          mode: "COD",
          displayText: "Payment on delivery",
          gatewayProviderConfig: "282672784636437fb712aa67045cbc7e",
          method: "OFFLINE",
        },
      ],
    };
    const axiosResponse = axios.post(url, body);
    return axiosCommons.resolveResponse(axiosResponse);
  },
  setPaymentMapping: (storeId) => {
    const url = new URL(`${paymentsBaseUrl}provider/config`);
    const body = {
      providerName: "COD",
      partyId : storeId
    };
    const axiosResponse = axios.post(url, body);
    return axiosCommons.resolveResponse(axiosResponse);
  }
};

export default storeOnboard;

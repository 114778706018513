import { Box, Button, TextField, Typography, Alert } from '@mui/material';
import React, { useState } from 'react';
import storeUserRestService from '../../services/rest/StoreUserRestService';

const RemoveUser = () => {
    const [user, setUser] = useState({ storeId: '', mobile: '' });
    const [loading, setLoading] = useState(false);
    const [userStatus, setUserStatus] = useState(false);
    const [error, setError] = useState('');

    const onChangeHandler = (e) => {
        setUser(prev => ({
            ...prev,
            [e.target.name]: e.target.value
        }))
    }

    const submitHandler = async () => {
        setLoading(true);
        setError('');
        setUserStatus(false);
        try {
            if (user.mobile.length === 10) {
                await removeUserFn(user.storeId, user.mobile);             
                setUser({ storeId: '', mobile: '' });
                setLoading(false);
                setUserStatus(true);
            }
            else {
                setLoading(false);
                setError("Pls enter valid mobile number");
            }
        } catch (err) {
            setLoading(false);
            setUserStatus(true);
            setError(err.message);
        }
    }

    const removeUserFn = async (storeId, mobile) => {
        try {
            await storeUserRestService.removeUser(storeId, mobile);
            return true;
        } catch (err) {
            throw new Error(err);
        }
    }

    return (
        <Box sx={{ mb: 14 }}>
            <Typography mb={1} color={'gray'}>Remove user from store</Typography>
            {
                userStatus && error.length === 0 &&
                <Alert severity='success' sx={{ my: 1 }}>User removed from store successfully</Alert>
            }
            <TextField sx={{ mb: 1, mt: 1 }} required name="storeId" onChange={onChangeHandler} value={user.storeId} label="StoreId" type='number' variant="outlined" fullWidth />
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                <TextField required name="mobile" onChange={onChangeHandler} value={user.mobile} label="Mobile Number" type='number' variant="outlined" fullWidth />
            </Box>
            {
                error.length > 0 &&
                <Alert severity='error' sx={{ my: 1 }}>{error}</Alert>
            }
            <Button disabled={loading} variant='contained' sx={{ color: 'white' }} color='secondary' type='submit' fullWidth onClick={submitHandler}>Remove User</Button>
        </Box>
    );
}

export default RemoveUser;
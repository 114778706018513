import React from "react";
import { Button, TextField } from "@mui/material";

const GetStoreDetails = ({
  storeId,
  setStoreId,
  isDisabled,
  handleStoreDetails,
}) => {
  return (
    <>
      <TextField
        required
        type='number'
        autoComplete='off'
        autoFocus
        sx={{ mt: 2, mb: 2 }}
        label='Store ID'
        variant='outlined'
        fullWidth
        value={storeId}
        onChange={(e) => {
          setStoreId(e.target.value);
        }}
      />
      <Button
        variant='contained'
        disabled={isDisabled}
        sx={{ color: "white", mt: 2 }}
        color='secondary'
        fullWidth
        onClick={handleStoreDetails}
      >
        Get Details
      </Button>
    </>
  );
};

export default GetStoreDetails;

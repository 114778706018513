var axiosCommons = {
    resolveResponse: function(axiosPromise, returnPromise) {
        return new Promise((resolve, reject) => {
            axiosPromise
        .then(response => {
            resolve(response.data);
        })
        .catch(error => {
            if(error.response) {
                reject(error.response.data.message);
            } else {
                reject("Unable to connect to server, please check internet connection");
            }
        })
        });
    }
}

export default axiosCommons;
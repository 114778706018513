import React from 'react'
import { Box } from '@mui/material';
import AddUser from '../../components/add-user/AddUser';

const AddUserPage = () => {
    return (
        <Box sx={{ ml: 2, mr: 2 }}>
            <AddUser />
        </Box>
    )
}

export default AddUserPage
import { db } from "./db";

export function getLoggedInStore() {
    return new Promise((resolve) => {
        db.appConext.get("loggedInSales").then(ctx => {
            if (ctx === undefined) {
                resolve(undefined);
            } else {
                resolve(ctx.value);
            }
        });
    })
}

export function putLoggedInStore(store) {
    db.appConext.put({
        key: "loggedInSales",
        value: { ...store }
    });
}

export function getToken() {
    return get("token");
}

export async function putToken(token) {
    await put("token", token);
}

export function getFirebaseToken() {
    return get("firebaseToken");
}

export function putFirebaseToken(firebaseToken) {
    put("firebaseToken", firebaseToken);
}

function put(key, value) {
    return db.appConext.put({
        key: key,
        value: value
    });
}

function get(key) {
    return new Promise((resolve) => {
        db.appConext.get(key).then(ctx => {
            if (ctx === undefined) {
                resolve(undefined);
            } else {
                resolve(ctx.value);
            }
        });
    })
}
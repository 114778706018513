import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  Select,
  MenuItem,
} from "@mui/material";
import catalogue from "../../services/rest/CatalogueRestService";
import { useSnackbar } from "../../utility/Snackbar";
import { useTheme } from '@mui/material/styles';

const ProductDetailss = ({ cropData }) => {
  const [formData, setFormData] = useState({
    categoryId: 1,
    brand: "",
    productName: "",
    type: "",
    mrp: 0,
    subCategory: "",
    source: "Self",
    defaultMetricsId: 1,
    description: "",
  });
  const { showSnackbar } = useSnackbar();
  const theme = useTheme();
  const [metricsData, setMetricsData] = useState([]);
  const handleChange = (field) => (event) => {
    setFormData({ ...formData, [field]: event.target.value });
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const fileData = new FormData();
      fileData.append("file", cropData);
      const response = await catalogue.ImageUpload(fileData);
      const res = await catalogue.SetCatalogueConfig({
        categoryId: formData.categoryId,
        brand: formData.brand,
        productName: formData.productName,
        type: formData.type,
        mrp: formData.mrp,
        subCategory: formData.subCategory,
        source: formData.source,
        imageId: response.id,
        defaultMetricsId: formData.defaultMetricsId,
        description: formData.description,
      });
      showSnackbar("Product Catalogue Added, Successfully", "success");
    } catch (error) {
      showSnackbar(error, "error");
    }
  };

  const getMetrics = async () => {
    try {
      const response = await catalogue.GetMetrics();
      setMetricsData(response);
    } catch (error) {
      showSnackbar(error, "Metrics not fetched");
    }
  };

  useEffect(() => {
    getMetrics();
  }, []);
  return (
    <>
      <Typography component='h5' color={theme.palette.primary.main} fontWeight={400} sx={{ mb: 2 }}>
        Product Form
      </Typography>
      <form
        style={{ display: "flex", flexDirection: "column", rowGap: "1rem" }}
        onSubmit={handleSubmit}
      >
        <TextField
          required
          label='Category ID'
          type='number'
          value={formData.categoryId}
          onChange={handleChange("categoryId")}
        />
        <TextField
          required
          label='Brand'
          value={formData.brand}
          onChange={handleChange("brand")}
        />
        <TextField
          required
          label='Product Name'
          value={formData.productName}
          onChange={handleChange("productName")}
        />
        <TextField
          required
          label='Type'
          value={formData.type}
          onChange={handleChange("type")}
        />
        <TextField
          required
          label='MRP'
          type='number'
          value={formData.mrp}
          onChange={handleChange("mrp")}
        />
        <TextField
          required
          label='Sub Category'
          value={formData.subCategory}
          onChange={handleChange("subCategory")}
        />
        <Box>
          <Typography required sx={{ marginBottom: "0.5rem" }}>
            Default Metrics
          </Typography>
          <Select
            value={formData.defaultMetricsId}
            onChange={handleChange("defaultMetricsId")}
          >
            {metricsData.map((metric) => (
              <MenuItem key={metric.id} value={metric.id}>
                {metric.displayText}
              </MenuItem>
            ))}
          </Select>
        </Box>
        <TextField
          required
          label='Description'
          multiline
          rows={4}
          value={formData.description}
          onChange={handleChange("description")}
        />

        <Button
          variant='contained'
          sx={{ color: "white" }}
          color='secondary'
          type='submit'
        >
          Save Changes
        </Button>
      </form>
    </>
  );
};

export default ProductDetailss;

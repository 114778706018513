import React, { useState, createRef } from "react";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { Button,Box, Typography, Input, FormControl } from "@mui/material";

const ImageCropper = ({ setCropData, cropData }) => {
  const [image, setImage] = useState(null);
  const cropperRef = createRef();

  const onChange = (e) => {
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }

    const reader = new FileReader();
    reader.onload = () => {
      setImage(reader.result);
    };
    reader.readAsDataURL(files[0]);
  };

  const getCropData = () => {
    if (typeof cropperRef.current?.cropper !== "undefined") {
      const canvas = cropperRef.current?.cropper.getCroppedCanvas({
        width: 512,
        height: 512,
      });

      const outputCanvas = document.createElement("canvas");
      outputCanvas.width = 512;
      outputCanvas.height = 512;
      const outputContext = outputCanvas.getContext("2d");
      outputContext.fillStyle = "white";
      outputContext.fillRect(0, 0, 512, 512);

      const offsetX = Math.max((512 - canvas.width) / 2, 0);
      const offsetY = Math.max((512 - canvas.height) / 2, 0);

      outputContext.drawImage(canvas, offsetX, offsetY);

      outputCanvas.toBlob(
        (blob) => {
          setCropData(
            new File([blob], "croppedImage.jpg", { type: "image/jpeg" })
          );
        },
        "image/jpeg",
        1.0
      );
    }
  };


  return (
    <Box  sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
       
      }}>
    

      <FormControl fullWidth sx={{ mb: 2 }}>
        <Input id='file-input' type='file' onChange={onChange} />
      </FormControl>

      {image && (
        <>
          <Cropper
            ref={cropperRef}
            style={{ width: "80%", aspectRatio: 1 }}
            zoomTo={0.5}
            initialAspectRatio={1}
            preview='.img-preview'
            src={image}
            viewMode={1}
            minCropBoxHeight={10}
            minCropBoxWidth={10}
            background={false}
            responsive={true}
            autoCropArea={1}
            checkOrientation={false}
            guides={true}
          />
          <Button
            fullWidth
            sx={{ color: "white", mt: 1 }}
            color='secondary'
            variant='contained'
            onClick={getCropData}
          >
            Crop Image
          </Button>
        </>
      )}

      {cropData && (
        <>
          <Typography variant='h5' gutterBottom sx={{ mt: 2 }}>
            Crop Preview
          </Typography>

          <img
            style={{ width: "100%" }}
            src={cropData && URL.createObjectURL(cropData)}
            alt='cropped'
          />
        </>
      )}
    </Box>
  );
};

export default ImageCropper;
